import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
// Todo: Re-add Form below after content creation
import { Col, Container, Row } from 'react-bootstrap';

import * as Globals from '../../globals';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import ContentCol from '../components/contentCol/contentCol';
import ChatBanner from '../components/chatBanner/chatBanner';
import Styles from './process.styles';

const ProcessTitleCol = ({ children }) => {
  return (
    <Col xs={11} sm={4} md={3} lg={3} className="text-sm-right">
      {children}
    </Col>
  );
};

const ProcessContentCol = ({ children }) => {
  return (
    <Col xs={11} sm={8} md={8} lg={7}>
      {children}
    </Col>
  );
};

const ProcessPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  return (
    <Layout>
      <Seo
        title="Our Process"
        description="A framework nimble enough to react to future
                  opportunities and focused enough to deliver on ambitious vision."
      />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <ContentCol>
                <h1>A Process For Entrepreneurial Growth</h1>
              </ContentCol>
            </Row>
          </Container>
        </BackgroundImage>

        <Container>
          <section className="my-5">
            <Row className="mb-5 justify-content-center text-center">
              <ContentCol>
                <p className="lead">
                  Our entrepreneurial framework is the key that allows us to
                  consistently unlock solutions nimble enough to react to future
                  opportunities and focused enough to deliver on a
                  founder&apos;s ambitious vision.
                </p>
              </ContentCol>
            </Row>
          </section>

          <hr />

          <section className="my-5">
            <Row className="mb-5 justify-content-center text-center">
              <ProcessContentCol>
                <h2>We're with you through every step of the journey.</h2>
              </ProcessContentCol>
            </Row>

            <div className="px-xl-4 process-steps">
              <Row>
                <ProcessTitleCol>
                  <h3>Definition</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-target"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#000000"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="1" />
                    <circle cx="12" cy="12" r="5" />
                    <circle cx="12" cy="12" r="9" />
                  </svg>
                </ProcessTitleCol>
                <ProcessContentCol>
                  <p>
                    Founders need products that demonstrate real traction in a
                    startup culture that prizes short-term results and long-term
                    potential. Our unique talent and proven framework allows us
                    to develop products nimble enough to react to future
                    opportunities, while helping our clients understand the
                    complex decisions that go into delivering on a
                    founder&apos;s ambitious vision.
                  </p>
                </ProcessContentCol>
              </Row>

              <Row>
                <ProcessTitleCol>
                  <h3>Planning</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-messages"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#000000"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10" />
                    <path d="M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2" />
                  </svg>
                </ProcessTitleCol>
                <ProcessContentCol>
                  <p>
                    We help our clients see the big picture, putting together a
                    strategy for dealing with all of the challenges they will
                    face along their journey. All founders dream big by nature.
                    It’s our job to find the path that will bring those dreams
                    into the real world. We believe that means intense focus,
                    knowing how and when to pivot, and working with leaders who
                    are willing to get their hands dirty.
                  </p>
                </ProcessContentCol>
              </Row>

              <Row>
                <ProcessTitleCol>
                  <h3>Execution</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-git-pull-request"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#000000"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="6" cy="18" r="2" />
                    <circle cx="6" cy="6" r="2" />
                    <circle cx="18" cy="18" r="2" />
                    <line x1="6" y1="8" x2="6" y2="16" />
                    <path d="M11 6h5a2 2 0 0 1 2 2v8" />
                    <polyline points="14 9 11 6 14 3" />
                  </svg>
                </ProcessTitleCol>
                <ProcessContentCol>
                  <p>
                    Working closely with founders to translate their business
                    idea into a successful product means devoting time to the
                    exploration and education around what constitutes minimum
                    viability. This emphasis gives the client a clear line of
                    sight into the complex decisions and competing priorities
                    that will shape their product as it evolves, leading to a
                    better creative partnership.
                  </p>
                </ProcessContentCol>
              </Row>

              <Row>
                <ProcessTitleCol>
                  <h3>Delivery</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-cloud-upload"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#000000"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M7 18a4.6 4.4 0 0 1 0 -9h0a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                    <polyline points="9 15 12 12 15 15" />
                    <line x1="12" y1="12" x2="12" y2="21" />
                  </svg>
                </ProcessTitleCol>
                <ProcessContentCol>
                  <p>
                    In addition to strategic advisory and full-stack development
                    of the product, we offer a deep pool of entrepreneurial and
                    executional knowledge. Clients receive long-term guidance on
                    everything from fundraising and team alignment to operations
                    and project management processes. This integrated framework
                    sets our products up for future growth.
                  </p>
                </ProcessContentCol>
              </Row>

              <Row>
                <ProcessTitleCol>
                  <h3>Value</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-live-photo"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#000000"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="1" />
                    <circle cx="12" cy="12" r="5" />
                    <circle cx="12" cy="12" r="9" strokeDasharray=".001 4.03" />
                  </svg>
                </ProcessTitleCol>
                <ProcessContentCol>
                  <p>
                    Besides delivering best-in-class technology, we deliver
                    value by forging a deep creative partnership with our
                    clients. Fully understanding a founder&apos;s vision means
                    that we can prepare for potential pivots and build a
                    foundation that can accommodate swift iteration in the
                    future. All of this groundwork paves the way for a
                    successful venture and sustained growth.
                  </p>
                </ProcessContentCol>
              </Row>

              <Row>
                <ProcessTitleCol>
                  <h3>Growth</h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-users"
                    width="48"
                    height="48"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="#000000"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                  </svg>
                </ProcessTitleCol>
                <ProcessContentCol>
                  <p>
                    We&apos;re along with you on the journey. By integrating our
                    product development framework into all of the clients&apos;
                    business operations, we can confidently hand things off when
                    they&apos;re ready to bring execution in-house. Typically
                    this happens around a Series A raise, but we&apos;ll stay
                    on-board in an advisory role for as long as we&apos;re
                    needed. Here&apos;s to whatever comes next!
                  </p>
                </ProcessContentCol>
              </Row>
            </div>
          </section>

          {/* Todo: Re-add after content creation */}
          {/*<hr />

          <section className="my-5">
            <Row className="justify-content-center text-center">
              <ContentCol>
                <h2>Process templates headline lorem ipsum</h2>
                <p className="lead mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enter
                  your email address to download these templates now
                </p>

                <Row className="mt-3 mb-4">
                  <Col>
                    <Img fluid={data.square.childImageSharp.fluid} />
                  </Col>
                  <Col>
                    <Img fluid={data.square.childImageSharp.fluid} />
                  </Col>
                </Row>

                <Form>
                  <Form.Group controlId="formEmail" className="w-75 mx-auto">
                    <Form.Label>Your Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Your Email Address"
                    />
                    <Form.Text className="text-muted">
                      We'll never share your email with anyone else.
                    </Form.Text>
                  </Form.Group>

                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </Form>
              </ContentCol>
            </Row>
          </section>*/}
        </Container>
      </Styles>
      <ChatBanner />
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-process.png" }) {
      ...imageFluidExtraLarge
    }
  }
`;

export default ProcessPage;
