import styled from '@emotion/styled';
import rhythm from '../utils/rhythm';
import breakpoints from '../utils/responsive';
import colors from '../styles/modules/colors.module.scss';

const Styles = styled.main({
  // Todo: Dry out Process / Work lists
  '.process-steps': {
    '.row': {
      marginBottom: `${rhythm(3)}`, // Equal to .mb-5
      justifyContent: 'center',

      '&:last-child': {
        marginBottom: 0,
      },

      svg: {
        stroke: `${colors.dark} !important`,
      },
    },

    [breakpoints.Mobile]: {
      '.row': {
        marginBottom: `${rhythm(2)}`, // Equal to .mb-5

        '&:last-child': {
          marginBottom: 0,
        },

        h3: {
          display: 'inline-block',
        },

        svg: {
          display: 'block',
          float: 'right',
          width: `${rhythm(1)}`,
          height: `${rhythm(1)}`,
        },
      },
    },
    [breakpoints.Phablet]: {
      '.row': {
        h3: {
          display: 'block',
        },
      },
    },
  },
});

export default Styles;
